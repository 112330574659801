<template>
  <div
    class="email-app-details bg-white"
  >

    <div class="email-detail-header">
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4
          v-if="onlyExternal === false"
          class="email-subject mb-0"
        >
          {{ $t('Adicionar angariação') }}
        </h4>
        <h4
          v-if="onlyExternal === true"
          class="email-subject mb-0"
        >
          {{ $t('Adicionar angariação externa') }}
        </h4>
      </div>
    </div>

    <vue-perfect-scrollbar
      class="email-scroll-area scroll-area pr-0 pl-0"
      :settings="configSettingsScroll"
    >

      <list-imoveis
        v-if="onlyExternal === false"
        :tabs-show="['favorites', 'list', 'myHUB']"
        :add-new="addNew"
      />

      <list-imoveis
        v-if="onlyExternal === true"
        :tabs-show="['externas']"
        :add-new="addNew"
        :only-external="true"
      />

    </vue-perfect-scrollbar>

  </div>
</template>

<script>
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import ListImoveis from '@/views/modules/components/modalListingSearch/index.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../listview/mixins'

export default {
  components: {
    VuePerfectScrollbar,
    ListImoveis,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    saveFormAngariacao: {
      type: Function,
      required: true,
    },
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    onlyExternal: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      search: [],
    }
  },
  computed: {
  },
  methods: {
    async addNew(pid) {
      if ((pid !== null) && (pid !== '')) {
        await this.saveFormAngariacao(pid)
      } else {
        this.showMsgErrorRequest(new Error(this.$t('Indique um ZMID')))
      }
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>
<style lang="scss" scoped>
.email-app-details {
  height: 100%!important;
  z-index: 3!important;
}

.ecommerce-application .email-app-details .app-fixed-search {
  padding: 0 0 0 0!important;
}

.ecommerce-application .email-app-details .app-fixed-search input {
  margin-top: 0.4rem;
}

.ecommerce-application .email-app-details .email-user-list {
  height: calc( 100% - calc(11.1rem) )!important;
}
</style>
